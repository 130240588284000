import { template as template_55566e61969b4c709bf95b812405913e } from "@ember/template-compiler";
const FKLabel = template_55566e61969b4c709bf95b812405913e(`
  <label for={{@fieldId}} ...attributes>
    {{yield}}
  </label>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKLabel;
